import React, { FC, useState } from "react"
import { FaBars } from 'react-icons/fa'



// const Header: FC = () => {
//   return (
//     <div className="flex flex-row w-full bg-white border-b border-gray-300 shadow-md justify-between " >
//       <div></div>
//       <div className=" flex self-center justify-self-center py-2 " >
//         <img className="h-8" src="/silver.png" />
//       </div>
//       <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
//         <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
//           <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page">Home</a>
//           </li>
//           <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
//           </li>
//           <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</a>
//           </li>
//           <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Pricing</a>
//           </li>
//           <li>
//             <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
//           </li>
//         </ul>
//       </div>
//     </div>
//   )
// }




const Header: FC = () => {

  const [show, setShow] = useState(false)

  return (
    <nav className="bg-white border-gray-300 shadow-md px-2 sm:px-4 py-2.5 ">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="w-40 hidden md:flex " ></div>
        <div className=" flex self-center self justify-self-center py-2 " >
          <img className="h-8" src="/silver_new.png" />
        </div>

        <button
          onClick={() => setShow(!show)}
          data-collapse-toggle="mobile-menu" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" >
          <FaBars />
        </button>
        <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <a href="#home" className="block py-2 pr-4 pl-3 text-white bg-red-700 rounded md:bg-transparent md:text-red-700 md:p-0 " aria-current="page">Home</a>
            </li>
            <li>
              <a href="#about" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0   ">About</a>
            </li>

            <li>
              <a href="#contact" className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0  ">Contact</a>
            </li>
          </ul>
        </div>

      </div>
      <div className={`w-full ${show ? "block" : "hidden"} absolute bg-white shadow-md border border-gray-200 rounded-md md:hidden md:w-auto `} >
        <ul className="flex flex-col">
          <li>
            <a href="#home" className="block py-2 pr-4 pl-3 text-white bg-red-700 rounded md:bg-transparent md:text-red-700 md:p-0 " aria-current="page">Home</a>
          </li>
          <li>
            <a href="#about" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0   ">About</a>
          </li>

          <li>
            <a href="#contact" className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0  ">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header