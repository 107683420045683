import React, { useEffect, useState } from "react"


import ProductCard from "../components/productCard"
import MainLayout from "../layouts/MainLayout"
import { useTransition, animated, useSpring, config } from 'react-spring'
import About from "../components/About"
import ContactUs from "../components/ContactUs"
import { FaArrowRight } from 'react-icons/fa'
import WhatsAppButton from '../components/whatsAppButton'


const CanonCard = () => (
  <ProductCard description="Specialize in optical, imaging, and industrial products, such as lenses, cameras, medical equipment, scanners, printers."
    link="https://in.canon/" image="/canon.png"
  />
)
const TuraCard = () => (
  <ProductCard description="Provides best in class papers for photo printing in different varieties such as Glossy, Lustre and Matt Papers"
    link="http://photostar-germany.de/" image="/tura.png"
  />
)

const SandiskCard = () => (
  <div className="h-full flex rounded-lg overflow-hidden items-stretch hover:shadow-lg " >
    <div className="max-w-sm bg-white rounded-lg border border-gray-200   ">
      <div className="p-20 h-52"
        style={{ backgroundImage: `url("/bg.jpg")`, backgroundPosition: "center center", backgroundSize: 'cover' }} >
        <img className="rounded-t-lg" src="/sandisk.png" alt="" />
      </div>
      <div className="p-5 mt-28 ">
        <p className="mb-3 font-normal text-gray-700 ">Specialized in all types of storage devices</p>
        <div className="flex flex-col gap-2 mt-28 " >
          <a href="https://kb-in.sandisk.com/app/ssd/a_id/23553" target="_blank" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ">
            Solid State Drive
            <FaArrowRight />        </a>
          <a href="https://kb-in.sandisk.com/app/mobilestorage/a_id/23552" target="_blank" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ">
            Flash Drive
            <FaArrowRight />  </a>
          <a href="https://kb-in.sandisk.com/app/cards/a_id/23556" target="_blank" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ">
            Memory Card
            <FaArrowRight />      </a>
        </div>
      </div>
    </div>
  </div>
)


const LeftMount = () => {
  const [show, set] = useState(true)
  const transitions = useTransition(show, {
    from: { opacity: 0, translateX: -200 },
    enter: { opacity: 1, translateX: 0 },
    leave: { opacity: 0, translateX: -200 },
    reverse: show,
    delay: 200,
    config: { ...config.molasses, duration: 2000 },
    onRest: () => set(!show),
  })
  return transitions(
    (styles, item) => item && <animated.div style={styles}>        <CanonCard /> </animated.div>
  )
}

const RightMount = () => {
  const [show, set] = useState(true)
  const transitions = useTransition(show, {
    from: { opacity: 0, translateX: 200 },
    enter: { opacity: 1, translateX: 0 },
    leave: { opacity: 0, translateX: 200 },
    reverse: show,
    delay: 200,
    config: { ...config.molasses, duration: 2000 },
    onRest: () => set(!show),
  })
  return transitions(
    (styles, item) => item && <animated.div style={styles}>        <TuraCard /></animated.div>
  )
}
const CentralMount = () => {
  const [show, set] = useState(true)
  const transitions = useTransition(show, {
    from: { opacity: 0, },
    enter: { opacity: 1, translateX: 0 },
    leave: { opacity: 0, },
    reverse: show,
    delay: 200,
    config: { ...config.molasses, duration: 2000 },
    onRest: () => set(!show),
  })
  return transitions(
    (styles, item) => item && <animated.div style={styles}>        <SandiskCard /> </animated.div>
  )
}

export default function IndexPage() {


  // useEffect(() => {
  //   if (items.length === 0) {
  //     setTimeout(() => {
  //       setItems([1, 2, 4, 7])
  //     }, 2000)
  //   }
  // }, [items])

  // setInterval(() => {

  // }, 5000)


  return (
    <MainLayout>
      <div id="home" className="flex justify-center" >


        <div className="flex flex-col gap-2 md:hidden" >
          <CanonCard />
          <SandiskCard />
          <TuraCard />

        </div>
        <div className="hidden  max-w-sm xl:max-w-screen-xl justify-center md:flex flex-wrap gap-4" >

          <LeftMount />

          <CentralMount />

          <RightMount />

        </div>
      </div>
      <div className="flex flex-col justify-center items-center" >
        <About />
        <ContactUs />
      </div>
      <WhatsAppButton number="919284807745" />
    </MainLayout>


  )
}