import React, { FC } from "react"
import { FaWhatsapp } from 'react-icons/fa'

type Props = {
  number: string
}

const WhatsAppButton: FC<Props> = ({ number }) => {
  return (
    <a
      href={`https://wa.me/${number}`}
      className=" fixed cursor-pointer bottom-8  right-8 bg-green-500 p-3 rounded-full " >
      <FaWhatsapp className="text-3xl text-white" />
    </a>
  )
}

export default WhatsAppButton 