import React, { FC } from 'react'


const ContactList: FC<{ label: string, value: string }> = ({ label, value }) => {
  return (
    <div className="my-12" >
      <h5 className='font-bold text-red-600 uppercase' >{label}</h5>
      <p className="mt-2 " >{value}</p>
    </div>
  )
}


const ContactUs: FC = () => {
  return (
    <div id='contact' className="my-20  md:max-w-screen-xl px-4 w-full " >
      <h2 className="text-4xl   underline   " >
        Contact
      </h2>
      <div className='flex flex-wrap mt-12 '>


        <div className='md:w-1/2 px-2 ' >
          <div  >
            <ContactList label='Email' value='info@ltiimaging.com' />
            <ContactList label='Phone Number' value='+91 7926767125' />
            <ContactList label='Location' value='Lti Imaging, Mahavir Nagar society, Vastrapur, Ahmedabad, Gujarat.' />
          </div>
        </div>
        <div className=' w-full flex flex-row justify-center md:w-1/2 px-2'>
          <iframe className="w-full b-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.5272084910484!2d72.52376901541082!3d23.04112552141539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b4ceaaaaaab%3A0xe2ad431e4f2708d1!2sLti%20Imaging%20Private%20Limited!5e0!3m2!1sen!2sus!4v1651902092031!5m2!1sen!2sus" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

        </div>
      </div>
    </div>
  )
}

export default ContactUs
