import React, { FC } from "react"
import About from "../components/About"
import ContactUs from "../components/ContactUs"
import Footer from "./Footer"
import Header from "./Header"

const MainLayout: FC = ({ children }) => {
  return (
    <div className=" scroll-smooth min-h-screen flex flex-col w-full overflow-hidden" >
      <Header />
      <div className=" flex flex-col justify-center mt-4 " >
        {children}
      </div>
      {/* <div className="flex flex-col justify-center items-center " >
        <About />
        <ContactUs />
      </div> */}

      {/* <Footer /> */}
    </div>
  )
}

export default MainLayout