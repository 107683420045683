import React, { FC } from "react"

const About: FC = () => {
  return (
    <div id="about" className="my-20  md:max-w-screen-xl px-4 " >
      <h2 className="text-4xl   underline   " >About</h2>
      <p className="mt-10 text-justify " >Promoters with a history and experience of over half century in photo trade and imaging industry, we are a company with a vision and vocation. We are committed to meeting the demands of the market, with creative and innovative solutions, complying with quality standards and the best service. Consolidating ourselves as the company in our genre, maintaining strategic relationships with our customers at a highest level, preparing ourselves to face the challenge of having a presence in the market.</p>
    </div>
  )
}

export default About