import React, { FC } from "react"

import { FaArrowRight } from 'react-icons/fa'
type Props = () => {
  image: string

  description: string
  link: string
}

const ProductCard: FC<Props> = ({ image, title, description, link }) => {
  return (

    <div className="h-full flex rounded-lg items-stretch " >
      <div className="max-w-sm bg-white rounded-lg border border-gray-200  hover:shadow-lg ">
        <div className="p-20 h-52 " >
          <img className="rounded-t-lg" src={image} alt="" />
        </div>
        <div className="p-5 mt-28 ">


          <p className="mb-3 font-normal text-gray-700 ">{description}</p>
          <div className="flex flex-row justify-end mt-28 " >
            <a href={link} target="_blank" className="inline-flex self-end items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ">
              Read more
              <FaArrowRight />    </a>
          </div>

        </div>
      </div>
    </div>



  )
}

export default ProductCard